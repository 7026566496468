import React, { useState } from 'react'
import { Modal } from '@mui/material'

import { Quest } from '../../types'
import { CloseIcon } from '../../icons'
import { GradientButton, ProgressBar } from '../ui'
import { formatText } from '../../utils/helpers'
import { translate } from '../../localization'

interface QuestRowProps extends Quest {
  onClaim: (quest_id: string) => void;
  onCloseModal: () => void;
}

const rewardImage = {
  wood: '../images/icons/wood.png',
  iron: './images/icons/iron.png',
  stone: './images/icons/stone.png',
  wheat: './images/icons/wheat.png',
  meta: './images/metaversium.png',
  xp: './images/icons/xp.png',
}

const QuestRow = ({
  onClaim, quest_id, image, rewards, title, description,
  is_published, is_claimed, can_be_claimed, progress, onCloseModal,
}: QuestRowProps) => {
  const [isClaiming, setIsClaimging] = useState(false)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  return (
    <>
      <div
        className={`quest-row ${!is_published ? 'coming-soon' : ''}`}
        onClick={() => {
          if (!is_published) return
          setIsDetailsOpen(true)
        }}
      >
        <div className="quest-title-container">
          <span>{formatText(title, 40)}</span>
          {is_published && !is_claimed && (
            <>
              <ProgressBar variant="determinate" value={progress} />
            </>
          )}
          <br />
        </div>
        <div className="quest-reward-container">
          <span>{rewards.join(', ')}</span>
        </div>
        <div className="quest-actions-container">
          {is_claimed && (
          <span className="center-text">
            {`🎉 ${translate('completed')}`}
          </span>
          )}
          {!is_claimed && (
          <>
            <GradientButton
              onClick={() => {
                if (!is_published) return
                setIsDetailsOpen(true)
              }}
              text={translate('view')}
              style={{ minWidth: '100px', marginRight: '10px' }}
            />
            <GradientButton
              onClick={async (e) => {
                setIsClaimging(true)
                e?.stopPropagation()
                if (!can_be_claimed) {
                  return
                }
                console.log('Claiming reward')
                await onClaim(quest_id)
                setIsClaimging(false)
                onCloseModal()
              }}
              isDisabled={!can_be_claimed || isClaiming}
              isLoading={isClaiming}
              text={translate('claim')}
              color="green"
              style={{ boxShadow: 'none', margin: '0 auto', minWidth: '100px' }}
            />
          </>
          )}
        </div>
      </div>
      <Modal
        open={isDetailsOpen}
        onClose={() => setIsDetailsOpen(false)}
        disableEnforceFocus={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ maxHeight: '80%' }}
      >
        <div className="jobs-modal-container quest-details-container">
          <div className="modal-close-icon" onClick={() => setIsDetailsOpen(false)}>
            <CloseIcon />
          </div>
          <div className="quest-details-modal-body">
            <div className="quest-details-image-container">
              <img className="quest-details-image" src={image} alt="nft" />
            </div>
            <div className="quest-details-info-container">
              <span className="quest-details-name">{title}</span>
              <span className="quest-details-desc">{description}</span>
              <div
                className="quest-details-rewards-container"
                style={{
                  justifyContent: rewards.length > 3 ? 'space-around' : 'space-between',
                  flexWrap: rewards.length > 3 ? 'wrap' : 'nowrap',
                }}
              >
                {rewards.map((reward, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="quest-details-reward" key={reward.split(' ')[0] + index} style={{ width: rewards.length > 3 ? '40%' : '31%' }}>
                    <img src={rewardImage[reward.split(' ')[1].toLowerCase()]} alt={reward.split(' ')[1]} />
                    <span className="quest-details-reward-text">{`+ ${reward.split(' ')[0]}`}</span>
                  </div>
                ))}
              </div>
              {!is_claimed && false && (
                <GradientButton
                  text={translate('claim')}
                  style={{
                    boxShadow: 'none', margin: '0 auto', marginTop: '24px',
                  }}
                  onClick={() => {
                    setIsDetailsOpen(false)
                  }}
                  isDisabled={!can_be_claimed}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default QuestRow
