import { PublicKey } from '@solana/web3.js'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import * as SPLToken from '@solana/spl-token'
import connection from './connection'
import CONFIG from '../config.json'

const fetchBalances = async (address) => {
  try {
    const response = await connection.getTokenAccountsByOwner(
      address,
      {
        programId: TOKEN_PROGRAM_ID,
      },
    )

    if (!response.value) {
      return {
        success: false,
        reason: 'Cannot fetch token accounts by owner',
      }
    }

    const balances = response.value.filter((item) => {
      const accountInfo = SPLToken.AccountLayout.decode(item.account.data)
      return CONFIG.tokens[accountInfo.mint.toString()]
    }).map((e) => {
      const accountInfo = SPLToken.AccountLayout.decode(e.account.data)
      return {
        type: CONFIG.tokens[accountInfo.mint.toString()],
        mint: new PublicKey(accountInfo.mint),
        amount: accountInfo.amount,
      }
    })

    return {
      success: true,
      balances,
    }
  } catch (e) {
    return {
      success: false,
      reason: e,
    }
  }
}

export default fetchBalances
