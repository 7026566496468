import React, { useState } from 'react'
import { Modal } from '@mui/material'

import { ResourceType } from '../types'
import ModalBuildings from './ModalBuildings'
import ModalWorkersContent from './ModalWorkersContent'

interface ModalBuildingProps {
  isOpen: boolean;
  handleClose: () => void,
  buildingType: string;
}

enum ModalState {
  buildings = 'buildings',
  workers = 'workers',
}

const ModalRoot = ({
  isOpen, handleClose, buildingType,
}: ModalBuildingProps) => {
  const [modalState, setModalState] = useState<ModalState>(ModalState.buildings)

  const onFarm = () => {
    setModalState(ModalState.workers)
  }

  const onCloseModal = () => {
    handleClose()
    setModalState(ModalState.buildings)
  }

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      disableEnforceFocus={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ maxHeight: '80%' }}
    >
      <>
        {modalState === 'buildings' ? (
          <ModalBuildings
            handleClose={onCloseModal}
            buildingType={ResourceType[buildingType]}
            onFarm={() => onFarm()}
          />
        ) : (
          <ModalWorkersContent
            handleClose={onCloseModal}
            type="farm"
            farmType={ResourceType[buildingType]}
          />
        )}
      </>
    </Modal>
  )
}

export default ModalRoot
