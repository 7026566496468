import React from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'

import { translate } from '../localization'

// import {
//   // QuestsIcon,
//   // AchievementsIcon,
//   // JobsIcon,
//   // MarketIcon,
// } from '../icons'

// import MarketIcon from '../../public/images/icons/market.png'

const TopNavigation = ({ handleModal }) => {
  const { wallet } = useWallet()

  return (
    <>
      <div className="top-navigation">
        <div className="">
          <img src="../images/icons/logo.png" alt="Metaversium Logo" width="167" />
        </div>
        <div className="nav-items">
          <div className="nav-item workers-button" onClick={() => handleModal('workers')}>
            <img src="../images/icons/worker.png" width="32" alt="" />
            <p>{translate('workers')}</p>
          </div>
          <div className="nav-item marketplace-button">
            <img src="../images/icons/market.png" width="32" alt="" />
            <p>
              <a href="https://metaversium.io/marketplace" target="_blank" rel="noreferrer">
                {translate('Marketplace')}
              </a>
            </p>
          </div>
          <div className="nav-item quests-button" onClick={() => handleModal('quests')}>
            <img src="../images/icons/quest.png" width="32" alt="" />
            <p>{translate('quests')}</p>
          </div>
          {/* <div className="nav-item">
            <AchievementsIcon />
            <p>{translate('achievements')}</p>
          </div> */}
          <div className="nav-item">
            {wallet ? (
              <WalletDisconnectButton />
            ) : (
              <WalletMultiButton />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TopNavigation
