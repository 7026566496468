import React, {
  useMemo, useCallback, useEffect, useState,
} from 'react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets'
import { clusterApiUrl } from '@solana/web3.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ConfettiContainer } from './components/ui'
import { TokenBalancesProvider } from './context/TokenBalancesProvider'
import { ConfettiProvider } from './context/ConfettiProvider'
import { BalancesSheeet, Navigations, OnBoardingContainer } from './components'
import HomePage from './pages/HomePage'
import { initLocalization } from './localization'

const queryClient = new QueryClient()

initLocalization()

const App = () => {
  const [isOnboardingRun, setIsOnboardingRun] = useState(false)
  const network = WalletAdapterNetwork.Devnet
  const endpoint = useMemo(() => clusterApiUrl(network), [network])
  const wallets = useMemo(() => [new PhantomWalletAdapter()], [])

  // eslint-disable-next-line no-console
  const onError = useCallback((error) => console.log(error), [])

  useEffect(() => {
    async function init() {
      const isOnboardingComplete = await localStorage.getItem('onBoarding')
      if (!isOnboardingComplete) setIsOnboardingRun(true)
    }
    init()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} onError={onError} autoConnect>
          <WalletModalProvider>
            <TokenBalancesProvider>
              <ConfettiProvider>
                <>
                  <OnBoardingContainer isOnboardingRun={isOnboardingRun} />
                  <Navigations />
                  <HomePage />
                  <BalancesSheeet />
                  <ToastContainer />
                  <ConfettiContainer />
                </>
              </ConfettiProvider>
            </TokenBalancesProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </QueryClientProvider>
  )
}

export default App
