import React, { useState } from 'react'

interface WorkerImageProps {
  src: string;
  status: string | false;
}

const WorkerImage = ({ src, status }: WorkerImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  return (
    <>
      <img
        alt="worker"
        src={src}
        className={`worker-image ${status ? 'busy' : ''} ${isImageLoaded ? 'display' : 'hide'}`}
        onLoad={() => setIsImageLoaded(true)}
      />
      <img
        src="./images/defaultImge.png"
        alt="defaultImage"
        className={`worker-image ${status ? 'busy' : ''} ${isImageLoaded ? 'hide' : 'display'}`}
      />
    </>
  )
}

export default WorkerImage
