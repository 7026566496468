/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import { Modal } from '@mui/material'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'

import QuestRow from './QuestRow'
import { SwitchTabs } from '../ui'
import { Quest } from '../../types'
import { CloseIcon } from '../../icons'
import useQuests from '../../hooks/useQuests'
import useConfetti from '../../hooks/useConffeti'
import CONFIG from '../../config.json'
import { translate } from '../../localization'
import useTokenBalances from '../../hooks/useTokenBalances'

interface ModalQuestsProps {
  isOpen: boolean;
  onCloseModal: () => void;
}

const Options = {
  new: 'new',
  completed: 'completed',
} as const

type SwitchOptions = typeof Options[keyof typeof Options]

const ModalQuests = ({ isOpen, onCloseModal }: ModalQuestsProps) => {
  const [quests, setQuests] = useState<Quest[]>([])
  const [sortedQuests, setSortedQuests] = useState<Quest[] | []>([])
  const { setIsRun } = useConfetti()

  const { updateBalances } = useTokenBalances()
  const [activeOption, setActiveOption] = useState<SwitchOptions>(Options.new)
  const { isLoading, data } = useQuests()
  const wallet = useWallet()
  const queryClient = useQueryClient()

  useEffect(() => {
    setQuests(data?.quests || [])
  }, [data?.quests, wallet?.publicKey])

  useEffect(() => {
    if (isOpen) {
      queryClient.invalidateQueries(['quests'])
    }
  }, [isOpen, queryClient])

  useEffect(() => {
    if (activeOption === Options.completed) {
      const completedQuests = quests.filter((quest) => quest.is_claimed)
      setSortedQuests([...completedQuests])
    } else {
      const newQuests = quests.filter((quest) => !quest.is_claimed)
      setSortedQuests([...newQuests])
    }
  }, [activeOption, quests])

  const onClaim = async (quest_id: string) => {
    try {
      const requestHeaders: HeadersInit = new Headers()
      requestHeaders.set('Content-Type', 'application/json')
      const result = await fetch(`${CONFIG.apiURL}/submit-quest`, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify({ address: wallet?.publicKey, quest_id }),
      })
      const questsData = await result.json()

      if (questsData.status === 'error' && questsData.description) {
        toast.error(questsData.description)
      } else if (questsData.status === 'success') {
        toast.success('Quest completed')
        setIsRun(true)
        setTimeout(() => updateBalances(), 2000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        disableEnforceFocus={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ maxHeight: '80%' }}
      >
        <div className="jobs-modal-container">
          <div className="jobs-modal-header-container">
            <div className="jobs-modal-header">
              <span>{translate('quests')}</span>
            </div>
            <div className="modal-close-icon" onClick={onCloseModal}>
              <CloseIcon />
            </div>
          </div>
          <div className="quests-modal-body">
            <SwitchTabs
              active={activeOption}
              options={Object.values(Options)}
              toggleSwitch={setActiveOption}
            />
            <div className="quests-table-header">
              <div>
                <span className="quests-table-header-text">{translate('quest').toUpperCase()}</span>
              </div>
              <div>
                <span className="quests-table-header-text">{translate('reward').toUpperCase()}</span>
              </div>
              <div />
            </div>
            {isLoading && (
            <h3 className="loading">
              <img src="../images/loading.svg" width="32" alt="" />
              <br />
              {translate('loadingQuests')}
            </h3>
            )}
            {sortedQuests.map((questInfo, index) => (
              <QuestRow
                {...questInfo}
                onClaim={onClaim}
                onCloseModal={onCloseModal}
                // eslint-disable-next-line react/no-array-index-key
                key={questInfo.quest_id + index}
              />
            ))}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalQuests
