import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useWallet } from '@solana/wallet-adapter-react'

import fetchWorkersAndBuildings from '../utils/nfts'
import useStakedToken from '../hooks/useStakedToken'
import useRewards from '../hooks/useRewards'
import { CloseIcon, DoneIcon } from '../icons'
import { GradientButton, SwitchTabs, WorkerImage } from './ui'
import { handleStake, handleUnstake } from '../utils/staking'
import { Worker } from '../types'
import { getKeyByValue } from '../utils/helpers'
import { translate } from '../localization'
import CONFIG from '../config.json'
import useTokenBalances from '../hooks/useTokenBalances'

interface ModalWorkersProps {
  handleClose: () => void;
  type: 'farm' | 'main';
  farmType: string | undefined;
}

type RewardType = {
  [stakeEntryId: string]: string
}

interface WorkerProps extends Worker {
  onSelected: boolean;
  onSelectWorker: (mint: string) => void;
  pendingReward: {
    wood: RewardType | undefined;
    stone: RewardType | undefined;
    iron: RewardType | undefined;
    wheat: RewardType | undefined;
  }
}

const Options = {
  all: 'all',
  busy: 'busy',
  available: 'available',
} as const

type SwitchOptions = typeof Options[keyof typeof Options]

const WorkerComponent = ({
  mint, name, status, pendingReward, onSelectWorker, onSelected, image,
}: WorkerProps) => (
  <div className={onSelected ? 'worker-container active' : 'worker-container'} onClick={() => onSelectWorker(mint)}>
    <div className={onSelected ? 'checkbox active' : 'checkbox'}>
      {onSelected && <DoneIcon />}
    </div>
    <WorkerImage src={image} status={status} />
    <div className="worker-info-container">
      <p className="worker-info-name">{name}</p>
      <div>
        <div className="worker-info-status">
          <div className={status ? 'worker-status-busy' : 'worker-status-free'} />
          <p>{status && pendingReward && pendingReward[status] ? `${pendingReward[status][mint]} ${status.toUpperCase()}` : translate('available')}</p>
        </div>
      </div>
    </div>
  </div>
)

const ModalWorkersContent = ({ handleClose, type, farmType }: ModalWorkersProps) => {
  const [selectedWorkers, setSelectedWorkers] = useState<Array<string>>([])
  const [lockedMints, setLockedMints] = useState({})
  const [isLoadingNfts, setIsLoadingNfts] = useState(false)
  const [isLoadingTx, setIsLoadingTx] = useState(false)
  const [workers, setWorkers] = useState<Worker[]>([])
  const [sortedWorkers, setSortedWorkers] = useState<Worker[]>([])
  const [activeOption, setActiveOption] = useState<SwitchOptions>(Options.all)

  const { wallet } = useWallet()
  const stakedTokens = useStakedToken()
  const { updateBalances } = useTokenBalances()

  /* TODO: check performance impact */
  /* TODO: monitor number of RPC requests */
  const rewards = {
    wood: useRewards(CONFIG.pools.wood).data,
    stone: useRewards(CONFIG.pools.stone).data,
    iron: useRewards(CONFIG.pools.iron).data,
    wheat: useRewards(CONFIG.pools.wheat).data,
  }

  const onSelectWorker = (mint: string) => {
    const isSelected = Boolean(selectedWorkers.find((selected) => selected === mint))
    if (isSelected) {
      setSelectedWorkers(selectedWorkers.filter((selected) => selected !== mint))
      return
    }
    setSelectedWorkers([...selectedWorkers, mint])
  }

  useEffect(() => {
    switch (activeOption) {
      case Options.all:
        setSortedWorkers([...workers])
        break
      case Options.available:
        setSortedWorkers([...workers.filter((worker) => !lockedMints[worker.mint])])
        break
      case Options.busy:
        setSortedWorkers([
          ...workers.filter(
            (worker) => (
              lockedMints[worker.mint]),
          ),
        ])
        break
      default:
        setSortedWorkers([...workers])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOption, workers])

  useEffect(() => {
    async function fetchNFTs() {
      if (stakedTokens.data) {
        // console.log('[Modal Buildings] Updating the list of locked workers')
        // console.log(stakedTokens.data)
        const busyWorkers = {}
        for (let i = 0; i < stakedTokens.data.length; i += 1) {
          const entry = stakedTokens.data[i].parsed
          busyWorkers[entry?.originalMint.toBase58()] = entry?.pool.toBase58()
        }
        setLockedMints(busyWorkers)
      }
      setWorkers([])
      if (wallet?.adapter.publicKey) {
        setIsLoadingNfts(true)
        // console.log('[Modal Buildings] Loading workers...')
        const response = await fetchWorkersAndBuildings(wallet?.adapter.publicKey)
        // console.log('[Modal Buildings] Workers', response)
        setIsLoadingNfts(false)
        if (response && response.workers) {
          setWorkers(response.workers)
        }
      }
    }
    fetchNFTs()
  }, [wallet?.adapter.publicKey, stakedTokens.data])

  return (
    <div className="jobs-modal-container jobs-modal-workers">
      <div className="jobs-modal-header-container">
        <div className="jobs-modal-header">
          <span>{translate('workers')}</span>
        </div>
        <div
          className="modal-close-icon"
          onClick={() => {
            setSelectedWorkers([])
            handleClose()
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="workers-modal-body">
        {type === 'main' && (
          <SwitchTabs
            active={activeOption}
            options={Object.values(Options)}
            toggleSwitch={setActiveOption}
          />
        )}
        <div className="workers-container">
          {isLoadingNfts && (
            <h3 className="loading">
              <img src="../images/loading.svg" width="32" alt="" />
              <br />
              {translate('loadingWorkers')}
            </h3>
          )}
          {wallet?.adapter.publicKey && !isLoadingNfts && !workers.length && (
            <h3 className="loading">{translate('noWorkers')}</h3>
          )}
          {!wallet?.adapter.publicKey && !isLoadingNfts && <h3 className="loading">{translate('walletNotConnected')}</h3>}
          {sortedWorkers.length > 0 && (
            sortedWorkers.map((worker) => (
              <WorkerComponent
                {...worker}
                key={worker.mint}
                status={lockedMints[worker.mint] ? getKeyByValue(CONFIG.pools, lockedMints[worker.mint]) : ''}
                pendingReward={
                  rewards || undefined
                  // eslint-disable-next-line max-len
                  // rewards[farmType] && rewards[farmType].data ? rewards[farmType].data[worker.mint] : undefined
                }
                onSelectWorker={onSelectWorker}
                onSelected={
                  Boolean(selectedWorkers.find((selected) => selected === worker.mint))
                }
              />
            ))
          )}
        </div>
      </div>
      {wallet?.adapter.publicKey && (
        <div className="jobs-modal-footer-container">
          {type === 'farm' ? (
            <GradientButton
              text={`${translate('farm')} (${selectedWorkers.length})`}
              style={{ marginTop: '0.8rem', height: '3.5rem' }}
              onClick={async () => {
                setIsLoadingTx(true)
                if (farmType) {
                  const result = await handleStake(
                    CONFIG.pools[farmType], selectedWorkers, wallet,
                  )
                  if (result.filter((item) => item !== null).length) {
                    stakedTokens.refetch()
                  }
                  console.log('Result is: ', result)
                  if (result) {
                    toast.success(translate('workerStartFarming'))
                  }
                } else {
                  toast.error('Farm is not defined')
                }
                setIsLoadingTx(false)
                setSelectedWorkers([])
              }}
              isDisabled={!selectedWorkers.length || isLoadingTx}
              isLoading={isLoadingTx}
            />
          ) : (
            <>
              <GradientButton
                text={`${translate('finish')} (${selectedWorkers.length})`}
                style={{ marginTop: '0.8rem', height: '3.5rem' }}
                onClick={async () => {
                  setIsLoadingTx(true)
                  const result = await handleUnstake(lockedMints, selectedWorkers, wallet)
                  if (result.filter((item) => item !== null).length) {
                    stakedTokens.refetch()
                  }
                  updateBalances()
                  setIsLoadingTx(false)
                  setSelectedWorkers([])
                }}
                isDisabled={!selectedWorkers.length || isLoadingTx}
                isLoading={isLoadingTx}
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ModalWorkersContent
