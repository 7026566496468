import React, { useState } from 'react'
import { Modal } from '@mui/material'

// import LeftNavigation from './LeftNavigation'
import TopNavigation from './TopNavigation'
import ModalWorkersContent from './ModalWorkersContent'
import ModalQuests from './ModalQuests'

type ModalStatusType = 'workers' | 'quests' | 'achievements' | null

const Navigation = () => {
  const [modalStatus, setModalStatus] = useState<ModalStatusType>(null)

  return (
    <>
      {/* <LeftNavigation handleModal={setIsModalJobsVisible} /> */}
      <TopNavigation handleModal={setModalStatus} />
      <Modal
        open={modalStatus === 'workers'}
        disableEnforceFocus={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ maxHeight: '80%' }}
      >
        <>
          <ModalWorkersContent
            handleClose={() => setModalStatus(null)}
            type="main"
            farmType=""
          />
        </>
      </Modal>
      <ModalQuests
        isOpen={modalStatus === 'quests'}
        onCloseModal={() => setModalStatus(null)}
      />
    </>
  )
}

export default Navigation
