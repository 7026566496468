import React, { useEffect, useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import { toast } from 'react-toastify'

import { getBuildingCost } from '../utils/helpers'
import { upgradeBuilding, getBuildingByType } from '../utils/buildings'
import { ResourceType } from '../types'
import { translate } from '../localization'
import { CloseIcon, TimeIcon, LockIcon } from '../icons'
import { GradientButton } from './ui'
import useTokenBalances from '../hooks/useTokenBalances'

const getBuildingName = (type: ResourceType) => {
  if (type === ResourceType.wood) {
    return translate('Timber Camp')
  }
  if (type === ResourceType.stone) {
    return translate('Stone Quarry')
  }
  if (type === ResourceType.iron) {
    return translate('Iron Mine')
  }
  if (type === ResourceType.wheat) {
    return translate('Wheat Farm')
  }
  return ''
}

interface ModalBuildingsProps {
  buildingType: ResourceType;
  handleClose: () => void;
  onFarm: () => void;
}

const ModalBuildings = ({
  buildingType, handleClose, onFarm,
}: ModalBuildingsProps) => {
  const wallet = useWallet()
  const [hasBuilding, setHasBuilding] = useState(false)
  const [isLoadingBuildings, setIsLoadingBuildings] = useState(false)
  const [isLoadingTx, setIsLoadingTx] = useState(false)
  const { updateBalances } = useTokenBalances()

  useEffect(() => {
    async function init() {
      setIsLoadingBuildings(true)
      const isHasBuilding = await getBuildingByType(buildingType, wallet)
      setHasBuilding(isHasBuilding)
      setIsLoadingBuildings(false)
    }
    init()
  }, [buildingType, wallet])

  return (
    <>
      <div className="jobs-modal-container">
        <div className="jobs-modal-header-container">
          <div className="jobs-modal-header">
            <span>{getBuildingName(buildingType)}</span>
          </div>
          <div className="modal-close-icon" onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        {!wallet?.publicKey ? <h3 className="loading">{translate('walletNotConnected')}</h3> : (
          <div className="buildings-modal-body">
            {isLoadingBuildings ? (
              <h3 className="loading">
                <img src="../images/loading.svg" width="32" alt="" />
                <br />
                {translate('loadingBuildings')}
              </h3>
            ) : (
              <>
                <div className="building-info-container">
                  <p className="building-level">
                    {`${translate('level')} ${hasBuilding ? 1 : 0}`}
                  </p>
                  <img className="building-image" src={`../images/land/${buildingType}-farm-${hasBuilding ? 2 : 1}.png`} alt="buildingImage" />
                  <div className="building-details-container">
                    <span className="building-status-text">{translate('unlocked')}</span>
                    <div className="building-rate-container">
                      <img width="48px" src={`../images/icons/${buildingType}.png`} alt="Resource" />
                      <div className="rate-info-container">
                        <span className="resourse-rate-text">{`${hasBuilding ? 54 : 36} ${translate(String(buildingType))}`}</span>
                        <div className="hour-rate-container">
                          <TimeIcon />
                          <span className="hour-rate-text">{translate('perHour')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="select-farming-container">
                    <GradientButton
                      style={{ height: '3.5rem', width: '100%' }}
                      textStyle={{ fontSize: '19px' }}
                      color="green"
                      text={translate('farm')}
                      onClick={onFarm}
                    />
                  </div>
                </div>
                {/* building cost block */}
                <div className={`building-info-container ${buildingType === ResourceType.wheat ? 'hidden' : ''}`}>
                  <p className="building-level">{`${translate('level')} ${hasBuilding ? 2 : 1}`}</p>
                  <img className="building-image" src={`../images/land/${buildingType}-farm-${hasBuilding ? 3 : 2}.png`} alt="Building" />
                  <div className="building-details-container">
                    <span className="building-status-text">{translate('constructionCost')}</span>
                    <div className="building-cost-container">
                      <div>
                        <div className="building-resourse-image">
                          <img src="../images/icons/wood.png" alt="Wood Tile" />
                        </div>
                        <div className="building-resourse-text-container">
                          <span className="building-resourse-type">
                            {translate('wood')}
                            :
                          </span>
                          <span className="building-resourse-number">{getBuildingCost(buildingType, hasBuilding ? 2 : 1).wood}</span>
                        </div>
                      </div>
                      <div>
                        <div className="building-resourse-image">
                          <img src="../images/icons/stone.png" alt="Stone Tile" />
                        </div>
                        <div className="building-resourse-text-container">
                          <span className="building-resourse-type">
                            {translate('stone')}
                            :
                          </span>
                          <span className="building-resourse-number">{getBuildingCost(buildingType, hasBuilding ? 2 : 1).stone}</span>
                        </div>
                      </div>
                      <div>
                        <div className="building-resourse-image">
                          <img src="../images/icons/iron.png" alt="Iron Tile" />
                        </div>
                        <div className="building-resourse-text-container">
                          <span className="building-resourse-type">
                            {translate('iron')}
                            :
                          </span>
                          <span className="building-resourse-number">{getBuildingCost(buildingType, hasBuilding ? 2 : 1).iron}</span>
                        </div>
                      </div>
                    </div>
                    <GradientButton
                      text={translate('upgrade')}
                      style={
                        {
                          marginTop: '0.8rem', height: '3.5rem',
                        }
                      }
                      isDisabled={isLoadingTx || hasBuilding}
                      isLoading={isLoadingTx}
                      onClick={async () => {
                        if (!wallet?.publicKey) {
                          toast.error(translate('walletNotConnected'))
                          return
                        }
                        // disable duble clicks
                        if (isLoadingTx || hasBuilding) {
                          return
                        }
                        try {
                          const responseData = await upgradeBuilding(
                            wallet, buildingType, setIsLoadingTx,
                          )
                          if (!responseData) {
                            toast.error('Cannot sign tx')
                            return
                          }
                          if (responseData.status === 'error' && responseData.description) {
                            toast.error(responseData.description)
                          } else if (responseData.status === 'success') {
                            toast.success(`${getBuildingName(buildingType)} upgraded`)
                            updateBalances()
                            setIsLoadingTx(false)
                            handleClose()
                            setTimeout(() => { window.location.reload() }, 1500)
                          }
                        } catch (error) {
                          // @todo: check all possible errors
                          toast.error('Not enough resources')
                          setIsLoadingTx(false)
                          console.log(error)
                        }
                      }}
                    />
                  </div>
                </div>

                {/* locked building */}
                <div className={`building-info-container-wrapper ${buildingType === ResourceType.wheat ? 'hidden' : ''}`}>
                  <div className="lock-icon-container">
                    <LockIcon />
                  </div>
                  <div className="building-info-container-locked building-lock">
                    <p className="building-level">{`${translate('level')} ${hasBuilding ? 3 : 2}`}</p>
                    <img className="building-image" src={`../images/land/${buildingType}-farm-${hasBuilding ? 4 : 3}.png`} alt="Building" />
                    <div className="building-details-container">
                      <span className="building-status-text">{translate('constructionCost')}</span>
                      <div className="building-cost-container">
                        <div>
                          <div className="building-resourse-image">
                            <img src="../images/icons/wood.png" alt="Wood Tile" />
                          </div>
                          <div className="building-resourse-text-container">
                            <span className="building-resourse-type">
                              {translate('wood')}
                              :
                            </span>
                            <span className="building-resourse-number">{getBuildingCost(buildingType, hasBuilding ? 3 : 2).wood}</span>
                          </div>
                        </div>
                        <div>
                          <div className="building-resourse-image">
                            <img src="../images/icons/stone.png" alt="Stone Tile" />
                          </div>
                          <div className="building-resourse-text-container">
                            <span className="building-resourse-type">
                              {translate('stone')}
                              :
                            </span>
                            <span className="building-resourse-number">{getBuildingCost(buildingType, hasBuilding ? 3 : 2).stone}</span>
                          </div>
                        </div>
                        <div>
                          <div className="building-resourse-image">
                            <img src="../images/icons/iron.png" alt="Iron Tile" />
                          </div>
                          <div className="building-resourse-text-container">
                            <span className="building-resourse-type">
                              {translate('iron')}
                              :
                            </span>
                            <span className="building-resourse-number">{getBuildingCost(buildingType, hasBuilding ? 3 : 2).iron}</span>
                          </div>
                        </div>
                      </div>
                      <GradientButton
                        text={translate('Buy')}
                        style={{ marginTop: '0.8rem', height: '3.5rem', cursor: 'not-allowed' }}
                        onClick={() => { }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ModalBuildings
