import { PublicKey } from '@solana/web3.js'
import { Metaplex, Nft } from '@metaplex-foundation/js'
import connection from './connection'
import CONFIG from '../config.json'
import { NFTs, Worker, Building } from '../types'

const metaplex = new Metaplex(connection)

const fetchWorkersAndBuildings = async (address: PublicKey): Promise<NFTs> => {
  try {
    const nftsmetadata = await metaplex.nfts().findAllByOwner(
      address,
    )

    const workers: Nft[] = []
    const buildings: Nft[] = []
    nftsmetadata.forEach((nft) => {
      if (nft.creators && nft.creators[0] && nft.creators[0].verified === true) {
        const creator = nft.creators[0].address.toBase58()
        const isWorker = CONFIG.characterCreators.includes(creator)
        const isBuilding = CONFIG.buildingCreators[creator]
        if (isWorker) {
          workers.push(nft)
        }
        if (isBuilding) {
          buildings.push(nft)
        }
      }
    })

    /* Disable for performance reasons, but good to have for the long term scalable solution */
    // const buildingsMetadata = await Promise.all(
    //   buildings.map(
    //     (item) => fetch(item.uri).then((response) => response.json()).then((d) => d.attributes),
    //   ),
    // )

    const workerImages = await Promise.all(
      workers.map(
        (item) => fetch(item.uri).then((response) => response.json()).then((data) => data.image),
      ),
    )
    const workersResponse: Worker[] = workers.map((item, index) => (
      {
        name: item.name, mint: item.mint.toBase58(), image: workerImages[index], status: '', currentWork: '',
      }
    ))
    const buildingsResponse: Building[] = buildings.map((item) => (
      {
        name: item.name,
        resource: item.creators ? CONFIG.buildingCreators[item.creators[0].address.toBase58()] : '',
        mint: item.mint.toBase58(),
        creator: item.creators ? item.creators[0].address.toBase58() : null,
        // attributes: buildingsMetadata[index],
      }
    ))

    return {
      workers: workersResponse,
      buildings: buildingsResponse,
    }
  } catch (e) {
    console.error(e)
    return { workers: [], buildings: [] }
  }
}

export default fetchWorkersAndBuildings
