import { PublicKey } from '@solana/web3.js'
import BN from 'bn.js'

export enum ResourceType {
  wood = 'wood',
  iron = 'iron',
  stone = 'stone',
  wheat = 'wheat',
}

export interface Worker {
  mint: string;
  name: string;
  status: string;
  currentWork: string;
  image: string;
}

export interface Building {
  creator: string | null;
  mint: string;
  name: string;
  resource: string;
  attributes?: { trait_type: string; value: string; }[],
  // level: string;
  // production: string;
  // storage: string;
}

export interface NFTs {
  workers: Worker[],
  buildings: Building[]
}

export interface Quest {
  quest_id: string,
  title: string,
  description: string,
  rewards: String[],
  image: string,
  is_published: boolean;
  progress: number;
  requiredAmount: number;
  currentAmount: number;
  can_be_claimed: boolean;
  is_claimed: boolean | null;
  transaction: string | null;
}

export type StakeEntryData = {
  bump: number;
  kind: number;
  pool: PublicKey;
  amount: BN;
  originalMint: PublicKey;
  originalMintClaimed: boolean;
  lastStaker: PublicKey;
  lastStakedAt: BN;
  totalStakeSeconds: BN;
  stakeMintClaimed: boolean;
  stakeMint: PublicKey | null;
  cooldownStartSeconds: BN | null;
}

export type AccountData = {
  pubkey: PublicKey;
  parsed: StakeEntryData;
}
