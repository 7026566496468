import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { useWallet } from '@solana/wallet-adapter-react'

import { ModalRoot } from '../components'
import sendReport from '../utils/reporting'
import CONFIG from '../config.json'
import fetchWorkersAndBuildings from '../utils/nfts'

const {
  LAYOUT, PLOTS, plotWidth, plotHeight, plotOffset,
} = CONFIG

const getBuildingClass = (buildingName: string) => {
  if (buildingName.includes('wood-farm')) return 'timber-camp'
  if (buildingName.includes('stone-farm')) return 'stone-quary'
  if (buildingName.includes('iron-farm')) return 'iron-mine'
  if (buildingName.includes('wheat-farm')) return 'wheat-farm'
  return ''
}

const HomePage = () => {
  // quick hack to copy multi dimensial array
  const [gameMap, setGameMap] = useState(JSON.parse(JSON.stringify(LAYOUT)))
  const [areBuildingsFetched, setAreBuildingsFetched] = useState(false)
  const [buildingTypeClicked, setBuildingTypeClicked] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [upgradeStep, setUpgradeStep] = useState(1)

  const wallet = useWallet()

  useEffect(() => {
    setAreBuildingsFetched(false)
    async function fetchNFTs() {
      if (wallet && wallet.publicKey) {
        const { buildings } = await fetchWorkersAndBuildings(wallet && wallet.publicKey)
        console.log('[Map] All buildings in the wallet', buildings)
        if (buildings && buildings.length) {
          const newMap = JSON.parse(JSON.stringify(LAYOUT))
          /* Temp solution for alpha test. We have max +1 level of the farm */
          buildings.forEach((building) => {
            if (building.resource === 'wood') {
              console.log('[Map] Found timber camp')
              newMap[1][2] = 3
            }
            if (building.resource === 'stone') {
              console.log('[Map] Found stone quarry')
              newMap[3][0] = 8
            }
            if (building.resource === 'iron') {
              console.log('[Map] Found iron mine')
              newMap[5][1] = 12
            }
          })
          setGameMap(newMap)
        }
      }
      setAreBuildingsFetched(true)
    }
    if (wallet && wallet.publicKey && !wallet.connecting && !wallet.disconnecting) {
      console.log('[Wallet connected]', wallet?.publicKey?.toBase58())
      sendReport({ address: wallet.publicKey.toBase58(), event: 'login' })
      fetchNFTs()
    } else if (!wallet.connected) {
      setAreBuildingsFetched(true)
      setGameMap(JSON.parse(JSON.stringify(LAYOUT)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet])

  const onBuildingClick = (type) => {
    setIsModalVisible(true)
    setBuildingTypeClicked(type)
  }

  const Map = () => {
    useEffect(() => {
      ReactTooltip.rebuild()
    }, [])

    console.log('Rendering map', new Date())
    let index = 0
    const map = gameMap.map((row, y) => row.map((column, x) => {
      index += 1
      const posX = x * plotWidth + ((y % 2) * plotWidth) / 2
      const posY = (y * plotHeight) / 2 - plotOffset * y
      const style = {
        width: `${plotWidth}px`,
        top: `${posY}px`,
        left: `${posX}px`,
      }
      const type = PLOTS[column]?.split('-')
      if (!type) { return <></> }

      return (
        <>
          <img
            data-for="building"
            src={`./images/land/${PLOTS[column]}`}
            className={`${column >= 1 && column <= 13 ? 'heptagon' : 'field'} ${getBuildingClass(PLOTS[column])}`}
            style={style}
            alt={PLOTS[column]}
            data-tip={type[1] === 'farm' ? type[0] : undefined}
            onClick={type[1] === 'farm' ? () => { onBuildingClick(`${type[0]}-${type[1]}`) } : undefined}
            key={`plot_${index}`}
          />
        </>
      )
    }))
    return <>{map}</>
  }

  const Upgrade = () => {
    console.log(`Upgrade step ${upgradeStep}`)
    const newMap = JSON.parse(JSON.stringify(gameMap))
    console.log(newMap)
    if (upgradeStep >= 1 && upgradeStep <= 3) {
      console.log(`[Map] Upgrading wood from ${newMap[1][2]} to ${newMap[1][2] + 1}`)
      newMap[1][2] += 1
    }
    if (upgradeStep >= 4 && upgradeStep <= 6) {
      newMap[3][0] += 1
    }
    if (upgradeStep >= 7 && upgradeStep <= 9) {
      newMap[5][1] += 1
    }
    if (upgradeStep === 10) {
      newMap[5][2] = 16
    }
    if (upgradeStep === 11) {
      newMap[4][2] = 17
    }
    if (upgradeStep === 12) {
      newMap[3][2] = 18
    }
    if (upgradeStep === 13) {
      newMap[1][0] = 21
    }
    if (upgradeStep === 14) {
      newMap[1][0] = 22
    }
    if (upgradeStep === 15) {
      newMap[2][1] = 19
    }
    if (upgradeStep === 16) {
      newMap[3][1] = 23
    }
    if (upgradeStep === 17) {
      newMap[6][1] = 24
    }
    setUpgradeStep(upgradeStep + 1)
    setGameMap(newMap)
  }

  return (
    <>
      <ReactTooltip
        place="top"
        textColor="white"
        id="building"
        backgroundColor="white"
        className="tooltip-container"
        globalEventOff="click"
        getContent={(dataTip) => (
          <img src={`../images/icons/${dataTip}.png`} alt="resource icon" />
        )}
      />
      {!areBuildingsFetched && (
        <div className="disable-map">
          <p>
            <img src="../images/loading.svg" width="32" alt="" />
            <span>Loading constructions from the wallet</span>
          </p>
        </div>
      )}
      <div id="map">
        <Map />
      </div>
      <button
        type="button"
        onClick={Upgrade}
        style={
          {
            position: 'fixed', bottom: 0, right: 0, margin: '25px', padding: '5px 20px', color: 'red',
          }
        }
      >
        Upgrade
      </button>
      <ModalRoot
        isOpen={isModalVisible}
        handleClose={() => {
          setIsModalVisible(false)
          setBuildingTypeClicked('')
        }}
        buildingType={buildingTypeClicked?.split('-')[0]}
      />
    </>
  )
}

export default HomePage
