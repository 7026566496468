import { useQuery } from 'react-query'
import { getStakeEntriesForUser } from '@cardinal/staking/dist/cjs/programs/stakePool/accounts'
import { useWallet } from '@solana/wallet-adapter-react'
import connection from '../utils/connection'
import { AccountData } from '../types'

const useStakedToken = () => {
  const { wallet } = useWallet()
  return useQuery<AccountData[]>(
    [
      'useStakedToken',
      wallet?.adapter.publicKey,
    ],
    async () => {
      if (!wallet?.adapter.publicKey) {
        return []
      }
      const stakeEntries = (
        await getStakeEntriesForUser(connection, wallet?.adapter.publicKey)
      )
      return stakeEntries.flat() as AccountData[]
    },
  )
}

export default useStakedToken
