import { useQuery } from 'react-query'
import { useWallet } from '@solana/wallet-adapter-react'

import { Quest } from 'types'
import CONFIG from '../config.json'

interface QuestResponse {
  status: string;
  quests: Quest[];
}

const useQuests = () => {
  const wallet = useWallet()
  const { isLoading, error, data } = useQuery<QuestResponse>(
    ['quests'],
    () => fetch(`${CONFIG.apiURL}/quests?address=${wallet?.publicKey}`).then((res) => res.json()),
    { cacheTime: 0, staleTime: 0 },
  )
  return {
    isLoading,
    error,
    data,
  }
}

export default useQuests
