import React from 'react'

interface GradientButtonProps {
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  text: string;
  style?: React.CSSProperties;
  color?: string;
  textStyle?: React.CSSProperties;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const GradientButton = ({
  onClick, text, color, style, textStyle, isDisabled, isLoading,
}: GradientButtonProps) => (
  <div
    onClick={onClick}
    style={style}
    className={`gradient-button ${isDisabled && 'disabled'} ${color === 'green' ? 'gradient-button-green' : 'gradient-button-pink'}`}
  >
    {isLoading && <img src="../images/loading.svg" width="32" alt="" />}
    <p style={textStyle} className="gradient-button-text">{text}</p>
  </div>
)

GradientButton.defaultProps = {
  color: 'gradient-button-pink',
  style: {},
  textStyle: {},
  isDisabled: false,
  isLoading: false,
}

export default GradientButton
