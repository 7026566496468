import {
  Transaction, PublicKey, sendAndConfirmRawTransaction, LAMPORTS_PER_SOL,
} from '@solana/web3.js'
// @ts-ignore:
import { TOKEN_PROGRAM_ID, createTransferCheckedInstruction } from '@solana/spl-token'
import CONFIG from '../config.json'
import { getKeyByValue, getBuildingCost } from './helpers'
import connection from './connection'
import fetchWorkersAndBuildings from './nfts'

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
  'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL',
)

interface BuildingResponse {
  status: string;
  description: string;
}

const upgradeBuilding = async (
  wallet, buildingType, setIsLoadingTx,
): Promise<(BuildingResponse | null
  )> => {
  const transaction = new Transaction();
  ['wood', 'stone', 'iron'].map(async (resource) => {
    if (!wallet?.publicKey) {
      return
    }
    const fromTokenAccount = (
      await PublicKey.findProgramAddress(
        [
          wallet?.publicKey.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          new PublicKey(getKeyByValue(CONFIG.tokens, resource)).toBuffer(),
        ],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
      )
    )[0]
    const toTokenAccount = (
      await PublicKey.findProgramAddress(
        [
          new PublicKey(CONFIG.characterCreators[0]).toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          new PublicKey(getKeyByValue(CONFIG.tokens, resource)).toBuffer(),
        ],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
      )
    )[0]
    transaction.add(
      createTransferCheckedInstruction(
        fromTokenAccount,
        new PublicKey(getKeyByValue(CONFIG.tokens, resource)),
        toTokenAccount,
        wallet?.publicKey,
        getBuildingCost(buildingType, 1)[resource] * LAMPORTS_PER_SOL,
        9, // decimals
      ),
    )
  })

  setIsLoadingTx(true)
  const recentBlockhash = await connection.getLatestBlockhash()
  transaction.feePayer = wallet.publicKey
  transaction.recentBlockhash = recentBlockhash.blockhash
  if (!wallet?.signTransaction) {
    return null
  }
  await wallet?.signTransaction(transaction)
  const signature = await sendAndConfirmRawTransaction(
    connection,
    transaction.serialize(),
  )
  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Content-Type', 'application/json')
  const result = await fetch(`${CONFIG.apiURL}/build`, {
    method: 'POST',
    headers: requestHeaders,
    body: JSON.stringify({
      address: wallet?.publicKey, building: buildingType, transaction: signature,
    }),
  })
  return result.json()
}

const getBuildingByType = async (buildingResource, wallet): Promise<boolean> => {
  const { buildings } = await fetchWorkersAndBuildings(wallet && wallet.publicKey)
  return !!buildings.find((building) => building.resource === `${buildingResource}`)
}

export {
  upgradeBuilding,
  getBuildingByType,
}
