import React from 'react'

import useTokenBalances from '../hooks/useTokenBalances'

const BalancesSheeet = () => {
  const { balances } = useTokenBalances()

  return (
    <div className="balances-sheet-container">
      <div className="balance-container">
        <img src="../images/icons/wood.png" alt="wood" />
        <span className="balance-text">{balances?.wood || 0}</span>
      </div>
      <div className="balance-container">
        <img src="../images/icons/stone.png" alt="stone" />
        <span className="balance-text">{balances?.stone || 0}</span>
      </div>
      <div className="balance-container">
        <img src="../images/icons/iron.png" alt="iron" />
        <span className="balance-text">{balances?.iron || 0}</span>
      </div>
      <div className="balance-container">
        <img src="../images/icons/wheat.png" alt="wheat" />
        <span className="balance-text">{balances?.wheat || 0}</span>
      </div>
      <div className="balance-container">
        <img src="../images/metaversium.png" className="meta-resource" alt="$META" />
        <span className="balance-text">{balances?.meta || 0}</span>
      </div>
    </div>
  )
}

export default BalancesSheeet
