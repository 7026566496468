import React, { useEffect, useState, useMemo } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import BN from 'bn.js'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

import fetchBalances from '../utils/balances'

export interface TokenBalances {
  wood: number;
  stone: number;
  iron: number;
  wheat: number;
  meta: number;
}

export interface ITokenBalancesContext {
  balances: TokenBalances | null,
  setBalances: (balances: TokenBalances | {}) => void;
  updateBalances: () => void;
}

const defaultTokenBalances = {
  balances: {
    wood: 0,
    stone: 0,
    iron: 0,
    wheat: 0,
    meta: 0,
  },
  setBalances: () => {},
  updateBalances: () => {},
}

export const TokenBalancesContext = React.createContext<ITokenBalancesContext>(defaultTokenBalances)

export function TokenBalancesProvider({ children }) {
  const { wallet } = useWallet()
  const [tokenBalances, setTokenBalances] = useState<TokenBalances | null>(
    defaultTokenBalances.balances,
  )

  const formatBalance = (balance) => {
    if (balance >= 1e9) {
      return `${(balance / 1e9).toFixed(2)}B`
    }
    if (balance >= 1e6) {
      return `${(balance / 1e6).toFixed(2)}M`
    }
    if (balance >= 1e3) {
      return `${(balance / 1e3).toFixed(2)}K`
    }
    return balance.toFixed(2)
  }

  const updateBalances = async () => {
    console.log('[Balance Sheet] Updating balances...', wallet?.adapter.publicKey?.toBase58())
    const response = await fetchBalances(wallet?.adapter.publicKey)
    console.log('[Balance Sheet] Balances:', response.balances)
    if (response.balances) {
      const updatedBalances = {}
      response.balances.forEach((balance) => {
        updatedBalances[balance.type] = formatBalance(
          new BN(balance.amount.toString()).div(new BN(LAMPORTS_PER_SOL)).toNumber(),
        )
      })
      setTokenBalances(updatedBalances as TokenBalances)
    }
  }

  useEffect(() => {
    if (wallet?.adapter.publicKey) {
      console.log('[Balance Sheet] Wallet changed', wallet?.adapter.publicKey?.toBase58())
      updateBalances()
    }
    if (!wallet?.adapter.publicKey && wallet?.adapter.disconnect) {
      setTokenBalances(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.adapter.publicKey])

  const balancesData = useMemo<ITokenBalancesContext>(
    () => ({
      balances: tokenBalances,
      setBalances: (newBalances) => setTokenBalances(newBalances as TokenBalances),
      updateBalances,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokenBalances],
  )

  return (
    <TokenBalancesContext.Provider value={balancesData}>
      {children}
    </TokenBalancesContext.Provider>
  )
}
